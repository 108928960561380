



































import Vue from 'vue';
import api from '@/api';
import CompanyAbstract from '@/models/CompanyAbstract';
// components
import MaterialInput from '@/components/MaterialInput.vue';

interface Data {
    companies: CompanyAbstract[],
    filter: string
}

export default Vue.extend({
    name: 'AssociatedProfiles',
    components: {            
        MaterialInput
    },
    data(): Data {
        return {
            companies: [],
            filter: ''
        }
    },
    computed: {
        filteredSet(): CompanyAbstract[] {
            return this.companies.filter(c => c.companyName.toUpperCase().includes(this.filter.toUpperCase()));
        }
    },
    async mounted() {
        try {
            const rslt = await this.$http.get(`${api}/profiles/current-user`);
            this.companies = rslt.data;
        } catch (error) {
            // eslint-disable-next-line
            console.error(error);
        }
    }
})
