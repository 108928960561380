import { NullableString } from './Types';

export default interface Credentials {
    username: string,
    password: string,
    twoFactorCode: NullableString
}

export const DefaultCredentials: Credentials = {
    username: '',
    password: '',
    twoFactorCode: null
}