



























































import Vue from 'vue';
import api from '@/api';
import User from '@/models/User';
// Components
import PersonalInfo from '@/components/account/PersonalInfo.vue';
import AccountSecurity from '@/components/account/AccountSecurity.vue';
import AssociatedProfiles from '@/components/account/AssociatedProfiles.vue';
// import BreadCrumb from '@/components/BreadCrumb.vue';

interface Data {
  user: User | null;
  selectedCard: number;
}

export default Vue.extend({
  name: 'ManageUserAccount',
  components: {
    PersonalInfo,
    AccountSecurity,
    AssociatedProfiles,
    // BreadCrumb,
  },
  data(): Data {
    return {
      user: null,
      selectedCard: 0,
    };
  },
  methods: {
    signout() {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('profile');
      window.location.assign('#/account/login');
    },
  },
  async mounted() {
    try {
      this.$store.commit('setIsLoading', true);
      const rslt = await this.$http.get(`${api}/account/abstract`);
      this.user = rslt.data as User;
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    } finally {
      this.$store.commit('setIsLoading', false);
    }
  },
});
