









































































































































































































    import Vue from 'vue';
    import api from '@/api';
    import OnboardingRequest, { DefaultRequest } from '@/models/OnboardingRequest';
    import Industry from '@/models/Industry';
    import Location from '@/models/Location';
    //components
    import MaterialInput from '@/components/MaterialInput.vue';
    import MaterialSelect from '@/components/MaterialSelect.vue';
    import MaterialMaskedInput from '@/components/MaterialMaskedInput.vue';
    import { OpenStreetMapProvider } from 'leaflet-geosearch';
    import 'leaflet/dist/leaflet.css';
    // validation
    import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
    import { required, regex, email } from 'vee-validate/dist/rules';

    extend('required', {
        ...required,
        message: '{_field_} is required'
    })

    extend('regex', {
        ...regex,
        message: "Please specify either 'http://' or 'https://' for your website"
    })

    extend('email', {
        ...email,
        message: '{_field_} must be a valid email'
    });

    interface State { id: string, name: string }
    interface Country { id: string, name: string }

    interface Data {
        request: OnboardingRequest;
        industries: Industry[];
        states: State[];
        countries: Country[]; 
        onFail: boolean;
    }

    export default Vue.extend({
    name: "Onboarding",
    components: {
        MaterialInput,
        MaterialSelect,
        MaterialMaskedInput,
        ValidationObserver,
        ValidationProvider
    },
    data(): Data {
        return {
            request: {...DefaultRequest },
            industries: [],
            states: [],
            countries: [],
            onFail: false
        };
    },
    computed: {
        industryId(): number | null {
            const industry = this.industries.find(industry => industry.name == this.request.industry);
            return industry ? industry.id : null;
        },
        getIndustries(): Industry[] {
            return this.industries.filter(industry => industry.parentId == null);
        },
        getSubIndustries(): Industry[] {
            const parentId = this.industryId;
            return parentId ? this.industries.filter(industry => industry.parentId == parentId) : [];
        }
    },
    methods: {
        sectorChanged: function(event: any) {
            this.request.subIndustry = '';
        },
        async lookupCoordinates(loc: Location) {
            // do geo lookup
            if(loc.streetAddress != null && loc.stateOrProvince != null) {
                const query = `${loc.streetAddress}, ${loc.stateOrProvince}`;
                const provider = new OpenStreetMapProvider();
                
                await provider.search({ query: query }).then((result) => {
                    if(result.length > 0) {
                        loc.lat = Number(result[0].raw.lat);
                        loc.long = Number(result[0].raw.lon);
                    }
                });
            }

            if(loc.lat == null || loc.long == null) {
                loc.long = 0;
                loc.lat = 0;
            }
        },
        async submit() {
            try {
                this.$store.commit('setIsLoading', true);
                if(this.request.hq.stateOrProvince == "-1") {
                    this.request.hq.stateOrProvince = null;
                }
                await this.lookupCoordinates(this.request.hq);
   

                // post to 'authenticate' endpoint
                const rslt = await this.$http.post(`${api}/request/signup`, this.request);
                this.onFail = false;
                window.location.assign('https://werkberry.com/');

            } catch (error) {
                this.onFail = true;
                // eslint-disable-next-line
                console.error(error);
            } finally {
                 this.$store.commit('setIsLoading', false);
            }
        }
    },
    async mounted() {

        this.request.externalId = this.$route.query.company_id as string | null;
        this.request.parentId = this.$route.query.parent_id as string | null;

        this.countries = [
            {id: 'USA', name: 'USA' },
            {id: 'Canada', name: 'Canada' }
        ]

        // states array
        this.states = [
            { id: 'AK', name: 'AK' },
            { id: 'AL', name: 'AL' },
            { id: 'AR', name: 'AR' },
            { id: 'AZ', name: 'AZ' },
            { id: 'CA', name: 'CA' },
            { id: 'CO', name: 'CO' },
            { id: 'CT', name: 'CT' },
            { id: 'DC', name: 'DC' },
            { id: 'DE', name: 'DE' },
            { id: 'FL', name: 'FL' },
            { id: 'GA', name: 'GA' },
            { id: 'HI', name: 'HI' },
            { id: 'IA', name: 'IA' },
            { id: 'ID', name: 'ID' },
            { id: 'IL', name: 'IL' },
            { id: 'IN', name: 'IN' },
            { id: 'KS', name: 'KS' },
            { id: 'KY', name: 'KY' },
            { id: 'LA', name: 'LA' },
            { id: 'MA', name: 'MA' },
            { id: 'MD', name: 'MD' },
            { id: 'ME', name: 'ME' },
            { id: 'MI', name: 'MI' },
            { id: 'MN', name: 'MN' },
            { id: 'MO', name: 'MO' },
            { id: 'MS', name: 'MS' },
            { id: 'MT', name: 'MT' },
            { id: 'NC', name: 'NC' },
            { id: 'ND', name: 'ND' },
            { id: 'NE', name: 'NE' },
            { id: 'NH', name: 'NH' },
            { id: 'NJ', name: 'NJ' },
            { id: 'NM', name: 'NM' },
            { id: 'NV', name: 'NV' },
            { id: 'NY', name: 'NY' },
            { id: 'OH', name: 'OH' },
            { id: 'OK', name: 'OK' },
            { id: 'OR', name: 'OR' },
            { id: 'PA', name: 'PA' },
            { id: 'RI', name: 'RI' },
            { id: 'SC', name: 'SC' },
            { id: 'SD', name: 'SD' },
            { id: 'TN', name: 'TN' },
            { id: 'TX', name: 'TX' },
            { id: 'UT', name: 'UT' },
            { id: 'VA', name: 'VA' },
            { id: 'VT', name: 'VT' },
            { id: 'WA', name: 'WA' },
            { id: 'WI', name: 'WI' },
            { id: 'WV', name: 'WV' },
            { id: 'WY', name: 'WY' },
            { id: 'AB', name: 'AB' },
            { id: 'BC', name: 'BC' },
            { id: 'MB', name: 'MB' },
            { id: 'NB', name: 'NB' },
            { id: 'NL', name: 'NL' },
            { id: 'NT', name: 'NT' },
            { id: 'NS', name: 'NS' },
            { id: 'NU', name: 'NU' },
            { id: 'ON', name: 'ON' },
            { id: 'PE', name: 'PE' },
            { id: 'QC', name: 'QC' },
            { id: 'SK', name: 'SK' },
            { id: 'YT', name: 'YT' }
        ]

        const rslt = await this.$http.get(`${api}/industries`);
        this.industries = rslt.data;
    }
});
