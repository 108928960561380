






















































import Vue from "vue";
//components
import BusySpinner from "@/components/modals/BusySpinner.vue";
import SignIn from "@/components/account/SignIn.vue";

interface Data {
  isSignup: boolean;
}

export default Vue.extend({
  name: "Login",
  components: {
    BusySpinner,
    SignIn,
  },
  data(): Data {
    return {
      isSignup: false,
    };
  },
});
