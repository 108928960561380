import { NullableString } from './Types';
import Location, { DefaultLocation } from './Location';

export interface Contact {
    email: string;
    firstName: string;
    lastName: string;
    title: NullableString;
    phone: NullableString; 
}

export default interface OnboardingRequest {
    externalId: NullableString;
    parentId: NullableString;
    companyName: string;
    industry: NullableString;
    subIndustry: NullableString;
    employerSize: string;
    hq: Location;
    website: NullableString;
    isLimited: boolean;
    paymentEmail: NullableString;
    pointOfContact: Contact;
}

export const DefaultRequest: OnboardingRequest = {
    externalId: null,
    parentId: null,
    companyName: '',
    industry: null,
    subIndustry: null,
    employerSize: '',
    hq: {...DefaultLocation },
    website: null,
    isLimited: true,
    paymentEmail: null,
    pointOfContact: { email: '', firstName: '', lastName: '', title: null, phone: null }
}