































    import Vue from 'vue';
    import api from '@/api';

    interface Data {
        isValid: boolean | null;
    }

    export default Vue.extend({
        name: 'Relay',
        data(): Data {
            return {
                isValid: null
            }
        },
        async mounted() {

            this.$bvModal.show('relay-modal');

            try {
                // validate the link
				const secret = this.$route.query.id;
                if(secret == undefined) {
                    throw new Error('id not found');
                }

				const rslt = await this.$http.get(`${api}/invite/validate/REDIRECT?id=${secret}`);
                this.isValid = true;
                sessionStorage.setItem("token", rslt.data.token); // assign generated bearer token
                this.$router.push({ path: `${rslt.data.path}`});

            } catch (error) {
                this.isValid = false;
            }

            this.$bvModal.hide('relay-modal');
        }
    });
