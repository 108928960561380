































































    import Vue from 'vue';
	import api from '@/api';
	// components
	import MaterialInput from '@/components/MaterialInput.vue';
	// validation
    import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
    import { required, email } from 'vee-validate/dist/rules';

    extend('required', {
        ...required,
        message: '{_field_} is required'
    });
    
    extend('email', {
        ...email,
        message: '{_field_} must be a valid email'
    });

	interface Data {
		form: { email: string, isComplete: boolean }
	}

    export default Vue.extend({
		name: 'RequestReset',
		components: {
			MaterialInput,
			ValidationObserver,
			ValidationProvider
		},
		data(): Data {
			return {
				form: {
					email: '',
					isComplete: false
				}
			}
		},
		methods: {
			async sendReset(){
				try {
					const rslt = await this.$http.get(`${api}/request/reset?email=${encodeURIComponent(this.form.email)}`);
					this.form.isComplete = true;
				} catch (error) {
					// eslint-disable-next-line
                    console.error(error);
				}
			}
		}
    })
