






























import Vue from 'vue';
import api from '@/api';
import Credentials, { DefaultCredentials } from '@/models/Credentials';
import UserSession from '@/models/UserSession';
// components
import MaterialInput from '@/components/MaterialInput.vue';
// validation
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, min } from 'vee-validate/dist/rules';


extend('required', {
    ...required,
    message: '{_field_} is required'
});

extend('min', {
    ...min,
    message: '{_field_} must be {length} characters long'
});

extend('email', {
    ...email,
    message: '{_field_} must be a valid email'
});

interface Data {
    user: Credentials,
    onFail: Boolean
}

export default Vue.extend({
    name: 'SignIn',
    components: {
        MaterialInput,
        ValidationProvider,
        ValidationObserver
    },
    data(): Data {
        return {
            user: { ...DefaultCredentials },
            onFail: false
        }
    },
    methods: {
        async login() {

            let btn = this.$refs.SignIn as HTMLButtonElement;

            try {
                btn.disabled = true;
                this.$store.commit('setIsLoading', true);

                // post to 'authenticate' endpoint
                const rslt = await this.$http.post(`${api}/account/authenticate`, this.user);
                const profile: UserSession = rslt.data;

                // save session vars
                sessionStorage.setItem("token", profile.token);
                this.$store.commit('setUserSession', profile);

                // if(typeof profile.primaryOrg != 'string') { //TODO: should exist?
                //     this.$router.push({ name: 'CreateProfile' });
                //     return;
                // }
                
                this.$router.push({ name: 'NavBoard', params: { pid: profile.primaryOrg! }});

            } catch (error) {
                this.onFail = true;
                // eslint-disable-next-line
                console.error(error);
            } finally {
                btn.disabled = false;
                this.$store.commit('setIsLoading', false);
            }
        }
    }
})
