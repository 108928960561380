
































































































    import Vue from 'vue';
	import api from '@/api';
	import Credentials, { DefaultCredentials } from '@/models/Credentials';
	// components
	import MaterialInput from '@/components/MaterialInput.vue';
	// validation
	import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
	import { required, email, confirmed } from 'vee-validate/dist/rules';

    extend('required', {
        ...required,
        message: '{_field_} is required'
    });
    
    extend('email', {
        ...email,
        message: '{_field_} must be a valid email'
    });

    extend('confirmed', {
        ...confirmed,
        message: '{_target_} and {_field_} values must match'
	});
	
	interface CredentialsVM extends Credentials {
		checksum: string
	}

	interface Data {
		link: {
			value: string,
			isValid: boolean | null
		}
		user: CredentialsVM,
		isComplete: boolean,
		validationFailed: boolean,
		validationMsg: string
	}

    export default Vue.extend({
		name: 'PasswordReset',
		components: {
			MaterialInput,
			ValidationObserver,
			ValidationProvider
		},
		data(): Data {
			return {
				link: {
					value: '',
					isValid: null
				},
				user: { ...DefaultCredentials, checksum: '' },
				isComplete: false,
				validationFailed: false,
				validationMsg: ''
			}
		},
		methods: {
			async resetPassword(){
				try {
					const rslt = await this.$http.put(`${api}/account/reset/${this.link.value}`, this.user, { headers: { 'Accept':'application/json'}});
					sessionStorage.setItem("token", rslt.data.token); //pointless for now
					this.isComplete = true;
				} catch (error: any) {
					const title = error.body.title as string;

					if(title.startsWith('One or more validation')){
						this.validationFailed = true;
						this.validationMsg = title;
					} else {
						this.link.isValid = false; // the link appears to be invalid for the requested user
					}
					
					// eslint-disable-next-line
					console.error(error);
				}
			}
		},
		async mounted() {
			try {
				// validate the link
				let secret = this.$route.query.id;
				const rslt = await this.$http.get(`${api}/invite/validate/RESET?id=${secret}`);
				this.link.value = secret as string;
				this.link.isValid = true;
			} catch (error) {
				this.link.isValid = false;
				// eslint-disable-next-line
				console.error(error);
			}
		}
    })
