var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 offset-lg-3 panel"},[_c('div',{staticStyle:{"display":"block"}},[_c('div',{staticClass:"row form-section signup"},[_c('div',{staticClass:"col-12"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[(!_vm.isComplete)?_c('div',[(_vm.link.isValid == null)?_c('div',[_vm._m(2)]):_vm._e(),(_vm.link.isValid != null && !_vm.link.isValid)?_c('div',[_vm._m(3),_c('div',{staticClass:"row form-section buttons"},[_c('div',{staticClass:"col text-right"},[_c('router-link',{staticClass:"btn btn-primary btn-raised",attrs:{"to":"/account/password-reset"}},[_vm._v("Request a Reset Link")])],1)])]):_vm._e(),(_vm.link.isValid)?_c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{attrs:{"id":"resetForm","method":"post","action":"#"},on:{"submit":function($event){$event.preventDefault();return _vm.resetPassword.apply(null, arguments)}}},[_c('div',{staticClass:"row form-section signin mt-4"},[_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"name":"Email Address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('MaterialInput',{attrs:{"id":"user_email","label":"Username/Email Address","note":"Specify the username/email to unlock","errors":errors},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"Password","name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('MaterialInput',{attrs:{"id":"password1","type":"password","label":"Password","note":"Required","errors":errors},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Confirmation","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('MaterialInput',{attrs:{"id":"password2","type":"password","label":"Confirm Password","note":"Required","errors":errors},model:{value:(_vm.user.checksum),callback:function ($$v) {_vm.$set(_vm.user, "checksum", $$v)},expression:"user.checksum"}})]}}],null,true)}),(_vm.validationFailed)?_c('label',{staticClass:"error"},[_vm._v("The following validation errors occurred: ")]):_vm._e()],1)]),_c('div',{staticClass:"row form-section buttons"},[_c('div',{staticClass:"col-6 text-left"},[_c('a',{staticClass:"btn btn-outline",attrs:{"href":"#","onclick":"window.history.back()"}},[_vm._v("Cancel")])]),_c('div',{staticClass:"col-6 text-right"},[_c('button',{staticClass:"btn btn-primary btn-raised",attrs:{"type":"submit","disabled":invalid}},[_vm._v("Reset Password")])])])])]}}],null,false,3288937924)})],1):_vm._e()]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isComplete),expression:"isComplete"}]},[_vm._m(4),_c('div',{staticClass:"row form-section buttons"},[_c('div',{staticClass:"col text-right"},[_c('router-link',{staticClass:"btn btn-primary btn-raised",attrs:{"to":"/account/login"}},[_vm._v("Return to Login")])],1)])])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',[_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":require("@/assets/img/werkberry-logo.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ul',{staticClass:"nav nav-tabs wb-tabs"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active"},[_vm._v("Reset Password (Step 2 of 2)")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row form-section signin mt-4"},[_c('div',{staticClass:"col"},[_c('h3',[_vm._v("Validating link...")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row form-section signin mt-4"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"text-left"},[_c('h3',[_vm._v("This link is expired or invalid")]),_c('label',[_vm._v("You can get a new reset link here:")])])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row form-section signin mt-4"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"text-left"},[_c('h3',[_vm._v("You have successfully updated your password.")]),_c('label',[_vm._v("You can now Login with your new credentials")])])])])])}]

export { render, staticRenderFns }