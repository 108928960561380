





































import Vue from 'vue';
import User from '@/models/User';
// components
import MaterialInput from '@/components/MaterialInput.vue';
import MaterialMaskedInput from '@/components/MaterialMaskedInput.vue';


export default Vue.extend({
    name: 'PersonalInfo',
    components: {    
        MaterialInput,
        MaterialMaskedInput 
    },
    props: {
        user: { type: Object as () => User, required: true }
    },
    methods: {            
        save() {
        }
    }
})
