

































import Vue from 'vue';
import User from '@/models/User';
// components
import MaterialInput from '@/components/MaterialInput.vue';

export default Vue.extend({
    name: 'AccountSecurity',
    components: {
        MaterialInput
    },
    props: {
        user: { type: Object as () => User, required: true }
    },
    methods: {
        changePassword() {

        },
        save() {

        }         
    }
})
